import { render, staticRenderFns } from "./CMSAdminComponentCarousel.vue?vue&type=template&id=37f84640&"
import script from "./CMSAdminComponentCarousel.vue?vue&type=script&lang=ts&"
export * from "./CMSAdminComponentCarousel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CMSAdminComponentCarousel.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCheckbox,VContainer,VForm,VSpacer})
