
































































import { Component, Vue } from 'vue-property-decorator';
import { Carousel } from '@/types/cms/components';
import axios, { APIResponse } from '@/plugins/axios';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * CMSAdminComponentCarousel component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-admin-component-carousel-item-list': () => import(
            '@/components/cms/components/admin/carousel/CMSAdminComponentCarouselItemList.vue'
        )
    }
})
export default class CMSAdminComponentCarousel extends Vue {
    // variable with form data
    private formData: Carousel = {
        id: -1,
        cycle: false,
        items: []
    }

    // Boolean that defines wheather form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     * Fetches data with fetchComponentContent function
     */
    private async created() {
        this.formData = await this.fetchComponentContent();
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Fetches component content from API
     * 
     * @returns Carousel
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchComponentContent(): Promise<Carousel> {
        try {
            const response = await axios.get<APIResponse<Carousel>>('/cms/components/carousel')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Saves form data
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveFormData() {
        axios.put<APIResponse>('/cms/components/carousel', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminComponents' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
